#foot{
    background-color: #141a1e;
    width: 100vw;
    min-height: 100px;
    margin-top: -100px;
}

#foot .lop{
    max-width: 100px;
    transform: scaleX(-1);
}

@media (max-width: 768px) {
    #foot .lop {
        max-width: 55px;
    }
    #foot{
        min-height: 55px;
        margin-top: -55px;
    }
}

@media (min-width: 768px) and (max-width: 1000px) {
    #foot .lop {
        max-width: 80px;
    }
    #foot{
        min-height: 80px;
        margin-top: -80px;
    }
}

@media (min-width: 1000px) and (max-width: 1200px) {
    #foot .lop {
        max-width: 85px;
    }
    #foot{
        min-height: 85px;
        margin-top: -85px;
    }
}