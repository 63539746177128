#nav {}

.lop {
    max-width: 100px;
}

.navbar {
    background-color: #141a1e;
}

.link-item {
    text-decoration: none;
    color: white;
    font-weight: bold;
}

.toggle{
    border: none;
}


@media (max-width: 768px) {
    .lop {
        max-width: 55px;
    }
}

@media (min-width: 768px) and (max-width: 1000px) {
    .lop {
        max-width: 80px;
    }
}

@media (min-width: 1000px) and (max-width: 1200px) {
    .lop {
        max-width: 85px;
    }
}

@media (min-width: 1200px) {
    .lop {
        max-width: 90px;
    }
}