.self{
   background-image: url('../../Assets/selfLibrary.JPG');
   background-repeat: no-repeat;
   background-size: cover;
   width: 100vw;
   min-height: 40vw;
   margin-bottom: 50px;
}

.lop-txt{
   background-color: #2f3f47;
   border-radius: 5px;
   padding: 30px;
 
}

@media (max-width:414px) {
   .self{
      background-image: none;
    }

    .second-self{
      background-image: url('../../Assets/selfLibrary.JPG');
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 60vw;
   }

   .lop-txt {
      margin-top: 5vh;
      margin-bottom: 5vh;
      background-color: #141a1e;
   }
}

@media (min-width: 415px) and (max-width: 767px) {
   .self{
     background-image: none;
   }

   .second-self{
      background-image: url('../../Assets/selfLibrary.JPG');
      background-repeat: no-repeat;
      background-size: cover;
      min-height: 60vw;
   }

   .lop-txt {
      margin-top: 5vh;
      margin-bottom: 5vh;
      background-color: #141a1e;
   }
}

@media (min-width: 768px) and (max-width: 990px) {
   .self{
      min-height: 60vw;
   }

   .lop-txt {
      margin-top: 5vh;
   }
}

@media (min-width: 991px) and (max-width: 1200px) {
   .lop-txt {
      margin-top: 8vh;
   }
}

@media (min-width: 1200px) {
   .lop-txt {
      margin-top: 10vh;
   }
}