#skills {
    font-weight: bold;
}
 #skills .icons {
    margin-top: 2%;
    margin-bottom: 2%;
    margin-left: 5px;
    width: 25px;
}

@media (max-width: 425px) {
    #skills .icons {
        display: none;
    }
}