@import url('https://fonts.googleapis.com/css2?family=Limelight&family=Literata&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');
 
@property --rotate {
  syntax: "<angle>";
  initial-value: 132deg;
  inherits: false;
}

body{
  background-image: linear-gradient(var(--rotate), #2f3f47, #141a1e);
  color: white;
  overflow-x: hidden;
}

.lime{
  font-family: 'Limelight', cursive;
}

.scp{
  font-family: 'Source Code Pro', monospace;
}

.miniTitle{
  font-size: 30px;
}


@media (max-width: 768px) {
  .miniTitle{
    font-size: 30px;
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 1000px) {
    
}

@media (min-width: 1000px) and (max-width: 1200px) {
    
}

@media (min-width: 1200px) {
    
}
