#projects .container {
    padding: 80px 0;
}

#projects .container .card {
    align-items: center;
    width: 350px;
    max-width: 100%;
    height: 385px;
    background: #141a1e;
    color: #fff;
    border-radius: 10px;
}

#projects .container .card .img-box {
    position: absolute;
    top: -100px;
    width: 300px;
    height: 190px;
    border-radius: 8px;
    overflow: hidden;
    scale: 0.75;
}

#projects .container .card .img-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#projects .container .card .content {
    position: absolute;
    top: 100px;
    width: 100%;
    height: 300px;
    padding: 0 30px;
    text-align: center;
    overflow: hidden;
}

#projects .container .card .content h2 {
    font-size: 1.3rem;
    font-weight: 700;
}

#projects .container .card .content a {
    position: relative;
    top: 15px;
    display: inline-block;
    padding: 12px 25px;
    text-decoration: none;
    color: white;
    font-weight: 500;
    border-radius: 10px;
    background-color: #2f3f47;
}

#projects .container .card .content a:hover {
    opacity: 0.8;
}

.card1, .card2, .card3{
    margin-bottom: 100px;
}

@media (max-width: 768px) {
    #projects .container .card {
        margin-left: auto;
        margin-right: auto;
        border-radius: 15px;
        height: 400px;
    }

    #projects .container .card .img-box {
        width: 90%;
        border-radius: 10px;
        top: -70px;
        scale: 0.75;
    }

    #projects .container .card .content {
        top: 130px;
        height: 250px;
    }

    #projects .container .card .content p {
        font-size: 0.9rem;
    }

    #projects .container .card .content a {
        font-size: 0.9rem;
    }

    .card1, .card2{
        margin-bottom: 80px;
    }
}
